<template>
  <div class="page-content">
    <div class="mx-1">
    </div>
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-header">
            <div class="row d-flex justify-content-between align-items-center pt-3">
              <div class="col-12 col-sm-12">
                <form class="search-form" @submit.prevent="doSearch()">
                  <div class="input-group border rounded-sm">
                    <div class="input-group-prepend">
                      <div class="input-group-text border-0 rounded-sm">
                        <FeatherIcon type="search" class="icon-md cursor-pointer" />
                      </div>
                    </div>
                    <input v-model="search.search" type="text" class="form-control  border-0 rounded-sm" id="searchForm" placeholder="Search here...">
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th>File Name</th>
                    <th>Category</th>
                    <th>Added Date</th>
                    <th colspan="4">#</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in documentList" :key="item.id">
                    <td>{{ item.file_name }}</td>
                    <td>{{ item.category }}</td>
                    <td>{{ item.created_at_str }}</td>
                    <td>
                      <a :href="item.full_url" target="_blank">
                        <button class="btn btn-outline-primary btn-sm mr-2">
                          <font-awesome icon="download"/>
                        </button>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <el-empty v-if="this.dataTotal == 0" description="No data"></el-empty>
            </div>
            <b-pagination v-if="this.dataTotal > 10" v-model="currentPage" :total-rows="this.dataTotal" :per-page="10"/>
          </div>
        </div>
      </div>
    </div>
    <!-- <div>
      <el-pagination
      :page-size="10"
      layout="prev, pager, next"
      :total="this.dataTotal"
      @current-change="setPage"
      >
      </el-pagination>
    </div> -->
  </div>
</template>
<style>
#btn_link {
  background:none;border: none;color: #1e5b2a;
}
#btn_link:hover {
  text-decoration: underline;
  color: #143d1c;
}
.btn-search {
  margin-left: 1em;
}
.pageBox {
  background-color: #1e5b2a;
  color: white;
  padding: 0.5em 1em;
  margin: 0.5em 1em;
  cursor: pointer;
  border: 0;
}
.pageBox:hover {
  color: white;
}
</style>
<script>
import moment from 'moment';
// import _ from 'lodash';
// import mime from 'mime';
// import useVuelidate from '@vuelidate/core';
// import { required } from '@vuelidate/validators';

import documentAPI from '../../../api/companyDocuments';
// import popupErrorMessages from '../../library/popup-error-messages';
// import base64File from '../../library/base64File';

export default {
  name: 'documentTracker',
  metaInfo: {
    title: 'Document Tracker',
  },
  data() {
    return {
      loading: {
        documentlist: false,
      },
      dataTotal: 0,
      dataSize: 0,
      page: 1,
      showModalAdd: false,
      showModalEdit: false,
      search: {
        search: this.$route.query.search || '',
        page: this.$route.query.page || 1,
        per_page: this.$route.query.per_page || 10,
      },
      documentList: [],
      documentCount: 0,
      loaderStack: 0,
      loader: null,
      documentForm: {
        company_id: '',
        file_name: '',
        category: '',
        file_path: null,
      },
      documentFormEdit: {
        company_id: '',
        file_name: '',
        category: '',
      },
      accepted_ext: {
        document: '.pdf, .xls, .xlsx, .docx, .doc, .csv, .ppt, .pptx, .txt',
      },
      company_list: [],
      last_index: null,
      get_company_id: this.$store.state.company.activeCompany.id,
    };
  },
  computed: {
    // page() {
    //   if (this.$route.query.page) {
    //     return this.$route.query.page;
    //   }
    //   return 1;
    // },
    currentPage: {
      get() {
        return this.search.page;
      },
      set(val) {
        console.log(val);
        this.search.page = val;
        this.page = val;
        this.loadList();
      },
    },
  },
  async mounted() {
    this.showLoader();
    await this.loadList();
    this.hideLoader();
  },
  methods: {
    doSearch() {
      // if (this.$route.query.page) {
      //   this.page = this.$route.query.page;
      // } else {
      //   this.page = 1;
      // }
      // this.loadList();
      this.page = 1;
      this.search.page = 1;
      this.search.per_page = 10;
      this.loadList();
    },
    setPage(val) {
      console.log(val);
      this.search.page = val;
      this.page = val;
      this.loadList();
    },
    async loadList() {
      // const options = {
      //   page: this.search.page.toString() || 1,
      //   per_page: this.search.per_page.toString() || 10,
      //   search: this.search.search,
      // };
      // if (!_.isEqual(options, this.$route.query)) {
      //   this.$router.replace({ query: options });
      // }
      const loader = this.$loading.show();
      this.loading.documentlist = true;
      await documentAPI.getList(this.search.page, this.search.search, this.get_company_id)
        .then((res) => {
          this.dataTotal = res.data.count;
          this.dataSize = Math.ceil(this.dataTotal / 10);
          this.documentList = res.data.rows.map((document) => {
            const data = document;
            ['created_at'].forEach((v) => {
              data[`${v}_str`] = document[v] ? moment(document[v]).format('DD MMM YYYY, HH:mm') : '-';
            });
            return data;
          });
        });
      this.loading.documentlist = false;
      loader.hide();
    },
    showLoader() {
      if (this.loaderStack === 0) {
        this.loader = this.$loading.show();
      }
      this.loaderStack += 1;
    },
    hideLoader() {
      this.loaderStack -= 1;
      if (this.loaderStack === 0) {
        this.loader.hide();
      }
    },
  },
};
</script>
